@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");

.App {
    /* text-align: center; */
    font-family: "Lato", sans-serif;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.ant-spin {
    color: #993b78 !important;
}

.ant-spin-dot-item {
    background-color: #993b78 !important;
}

.loginPage {
    background-color: #fff;
    width: 100%;
    height: 100vh;
}

.loginBtn {
    background-color: #5b2d4b !important;
    border: #5b2d4b !important;
    border-radius: 50px !important;
    height: 50px !important;
    width: 100%;
    font-weight: bold !important;
    font-size: 18px !important;
}

.loginInput {
    line-height: 3rem !important;
    font-size: 18px !important;
}

/* .loginInput::placeholder
{
  font-size: 18px !important;
} */

.errorHandel {
    color: red;
    font-size: 18px;
    font-weight: bold;
}

.fa-tools {
    color: #993b78;
}

.forget {
    color: #993b78 !important;
    cursor: pointer;
}

.orderDetailsInfo1 {
    font-weight: bold;
    font-size: 13px;
    color: grey;
}

.orderDetailsInfo2 {
    font-weight: 400;
    font-size: 13px;
    color: grey;
}

.ant-pagination-item-active:hover,
.ant-pagination-item-active,
.ant-pagination-item-active a {
    border-color: #993b78 !important;
    color: #993b78 !important;
}

.formGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px 16px;
    align-items: center;
    margin-bottom: 16px;
}

.formGrid label {
    width: 100%;
}

.notificationButton {
    border-radius: 50px;
    width: 50px;
    height: 50px;
    border: none;
    outline: none;
    background: transparent;
    transition: background-color 200ms ease-in;
    position: relative;
}

.notificationButton:hover {
    background: rgba(211, 211, 211, 0.75)
}

.notificationDot {
    max-width: 30px;
    width: 30px;
    background: rebeccapurple;
    position: absolute;
    right: 0;
    bottom: 0;
    color: white;
    font-size: 12px;
    border-radius: 10px;
    transition: scale 50ms ease-in-out;
    scale: 1;
}

.notificationDotRead {
    scale: 0;
}

.textOverflow {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
    cursor: pointer;
}

.btn-primary .ant-spin-dot-item {
    background: white !important;
}

.btn-primary .ant-spin {
    margin-inline-end: 8px;
    height: 20px;
}