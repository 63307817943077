.imageViewerContainer {
    display: flex;
    flex-direction: column;
    height: 70vh;
}

.currentImage {
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imagesContainer {
    display: flex;
    flex-direction: row;
    height: 90px;
    justify-content: space-between;
    background-color: #F7ECF3;
    margin: 0px 10px 10px 10px;
    border-radius: 15px;
}

.imagesList {
    text-align: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 5px 0px;
    overflow-x: auto;

}


.imagesList img{
    height: 70px;
    width: 70px;
}

.viewedImage {
    transition: transform .2s;
    max-height: 100%;
    max-width: 100%;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 5px;

    /* flex-grow: 1; */
}

.buttonsContainers {
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scrollButtonsIcon {
    width: 30px;
    height: 30px;
    color: #993B78;
    border-radius: 100%;
    padding: 3px;
}

.scrollButtonsIcon:hover {
    background-color: #e2e2e277;
    cursor: pointer;

}

.scrollButtonsIcon:active {
    color: #FDC16A;
}

.zoomButtons {
    background-color: #993B78;
}

.zoomButtons:hover { 
    background-color: #FDC16A;
}

.zoomButtons:active { 
    background-color: #FDC16A !important;
}

.zoomButtons:disabled {
    background-color: #996487;
}

.loadingFragment {
    position: absolute;
    z-index: 3;
    /* background-color: #993B78; */
    /* height: 100%; */
}