.panelLabel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.panelLabel button {
    background-color: transparent;
    border: none;
    outline: none;
    width: 32px;
    height: 32px;
    border-radius: 50px;
}

.panelLabel button:hover {
    background-color: rgba(211, 211, 211, 0.58);
}