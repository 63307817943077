.myNav
{
  box-shadow: 0px 5px 6px 3px #ccc;

}

.logo {
  width: 175px;
  padding: 7px;
  padding-left: 40px;
}
.fa-bell,
.fa-envelope {
  color: #b8c3d5;
  font-size: 20px;
}
.adminDrop a,
.adminDrop a:hover {
  color: black;
  font-size: 18px;
}
.dropdown-menu {
    top:150%;
  right: 15% !important;
  left: unset !important;
}
.dropdown-item
{
    padding: 10px 100px 15px 20px;
    color : #6c757d

}
.dropdown-item:hover
{
    background-color: #e8eaec;

}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-left: 10px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #22ca80;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #22ca80;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .myDrop button,  .myDrop button:active,  .myDrop button:hover,.myDrop button:focus
  {
background-color: transparent !important;
border: unset !important;
box-shadow: unset !important;

}
