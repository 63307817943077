.container {
    position: fixed;
    z-index: 10;
    bottom: 0;
    right: 24px;
    border-radius: 10px 10px 0 0;
    border: 1px solid lightgrey;
    transform: translateY(485px);
    transition: transform 0.2s ease-in-out;
    max-width: 352px;
    background: #E3D5E6;
}

.container > header {
    background: #E3D5E6;
    padding-inline: 15px;
    padding-top: 18px;
    border-radius: 10px 10px 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.container > header button {
    transition: transform 0.2s ease-in-out;
}

.container > header h5 {
    margin-bottom: 0 !important;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    text-overflow: ellipsis;
}

.open {
    transform: translateY(0px);
}

.open > header button {
    transform: rotate(180deg);
}