.showMenuBtn {
  background-color: #fff;
  box-shadow: 4px 3px 8px 2px #ccc;
  border: none;
  position: absolute;
  top: 0px;
  left: 100%;
  height: 50px;
  width: 100px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  font-size: 30px;
  outline: none !important;
}

@keyframes hideModel {
  from {
    left: 0;
  }
  to {
    left: -220px;
  }
}
@keyframes showModel {
  from {
    left: -220px;
  }
  to {
    left: 0;
  }
}

.my-menu {
  position: fixed;
  width: 220px;
  height: 100%;
  top: 115px;
  background-color: #fff;
  box-shadow: 0px 5px 6px 3px #ccc;
  z-index: 11;
}

.my-menu.show {
  animation-name: showModel;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  left: 0;
}

.my-menu.hide {
  animation-name: hideModel;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  left: -220px;
}

.side-list {
  list-style-type: none;
  padding: unset;
  padding-top: 15px;
}
.side-list li a {
  color: #818181;
  font-size: 16px;
  padding: 12px 30px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  line-height: 27px;
  opacity: 0.7;
  text-decoration: none;
}
.side-list i {
  color: #9eabc0 !important;
  /* margin-right: 12px; */
}
.side-list .navitem-active {
  border-radius: 0 60px 60px 0;
  color: #fff !important;
  background: linear-gradient(
    to right,
    #5a2c4a,
    #963c76,
    #b3478d,
    #ce3b9b,
    #c72c91
  );
  box-shadow: 0 7px 12px 0 rgba(95, 118, 232, 0.21);
  opacity: 1;
}
