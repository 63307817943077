.nav.nav-pills .nav-link.active {
    background-color:#993B78
}

.noSelection {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
        
}

.groupingDate {
    text-align: center;
    margin: 5px;
    color: #7B2F60;
    font-weight: 600;
}

.chatContainer {
    display: flex;
    flex-direction: column;
    /* height: 100%; */
    height: 500px;
    min-width: 350px;
    background-color: #F7ECF3;
}

.noMessagesAlert {
    display: flex;
    height: 700px;
    align-items: center;
    justify-content: center;
}

.chatHeader {
    background-color:#E3D5E6;
    padding: 15px 10px 0px 15px;
    font-weight: bold; 
    z-index: 2; 
}

.messagesList {
    flex-grow: 1;
    overflow-y: auto;
    padding: 0px 10px;
}

.inputFragment {
    display: flex;
    /* align-content: center; */
    padding: 8px;

    align-items: center;
  justify-content: center;
  
}

.messageTextField{ 
    flex-grow: 1;
    background: #E3D5E6;
    border: 2px solid #ffffff00;
    padding: 8px 8px;
    border-radius: 20px;
    outline: none;
    margin: 0px 10px 0px 5px;
    min-width: none;
    max-height: 35px;
}
.chatButtonsContainer {
    display: flex;
    width: 33px;
    height: 34px;
    align-items: center;
    justify-content: center;
    padding: 1.8px;
    position: relative;
    flex-grow: 0;
    /* top: 3px; */
    border-radius: 100%;
    cursor: pointer;
}

.chatButtons {
    color: #993B78;
    
    /* border-radius: 100%; */
    /* margin: 3px; */
    padding: 3.5px;
    width: 30px;
    height: 30px;
    margin: auto 0px;
    transition: width 0.1s, height 0.1s;
}



.chatButtonsContainer:hover {
    background-color: #d1c4d465;
    cursor: pointer;
    border-radius: 100%;
    
}


.chatButtons:active {
    color: #FDC16A;
    /* width: 29px;
    height: 29px; */
}

.previewImages {
    border-radius: 15px;
    min-height:70px;
    max-height: 100px;
    margin: 3px;
    object-fit: cover;
    padding: 2px;
  }

  .imageContainers:hover {
    cursor: pointer;
  }

  .loadMoreButton {
      background-color: #993B78;
      color: white;
      border-radius: 15px;
      margin: 10px;
      text-align: center;
  }

  .loadMoreButton:hover {
    background-color: #FDC16A;
  }