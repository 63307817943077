.table {
    border: 1px solid #993b78;
    border-radius: 8px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1px 2fr;

}


.divider {
    background-color: #993b78;
    height: 100%;
    width: 1px;
}

.emiratesList {
    display: flex;
    flex-direction: column;

}

.emirateListItem {
    display: flex;
    flex-direction: row;
    gap: 8px;
    font-size: 18px;
    padding: 8px 16px;
    border-bottom: 1.5px solid #993b78;
    justify-content: space-between;
    overflow: auto;
}

.emirateListItem:last-child {
    border: none;
}

.table hr {
    background-color: #993b78;
    opacity: 1;
    margin: 0;
    margin-bottom: 16px;
}

.emiratePrice {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 16px;
    font-size: 18px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.table input {
    border-radius: 4px;
    outline: none;
    border: 1px solid gray;
}


.areasList {
    background-color: #993b7811;
    min-height: 100%;
}

.selected {
    background-color: lightgrey;
}

.tableHeader select {
    font-size: 20px;
    background-color: transparent;
}

.tableHeader input,
.tableHeader input::placeholder {
    font-size: 20px;
    background-color: transparent;
}