.container {
}

.common {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    align-items: flex-end;
    gap: 16px;
}

.service {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px 16px;
    align-items: center;
    margin-bottom: 16px;
}

.service header {
    grid-column: 1/3;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.service header h5 {
    margin-bottom: 0 !important;
}

.service button {
    margin-top: 9px;
}

.serviceList {
    margin: 16px 0 ;
}

.serviceList button {
    background-color: #993b78;
    padding: 8px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 4px;
    align-items: center;
}

.serviceList button:disabled {
    background-color: darkgray !important;
}

.serviceList form div {
    margin-bottom: 0;
}