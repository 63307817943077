@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
    /* text-align: center; */
    font-family: "Lato", sans-serif;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.ant-spin {
    color: #993b78 !important;
}

.ant-spin-dot-item {
    background-color: #993b78 !important;
}

.loginPage {
    background-color: #fff;
    width: 100%;
    height: 100vh;
}

.loginBtn {
    background-color: #5b2d4b !important;
    border: #5b2d4b !important;
    border-radius: 50px !important;
    height: 50px !important;
    width: 100%;
    font-weight: bold !important;
    font-size: 18px !important;
}

.loginInput {
    line-height: 3rem !important;
    font-size: 18px !important;
}

/* .loginInput::placeholder
{
  font-size: 18px !important;
} */

.errorHandel {
    color: red;
    font-size: 18px;
    font-weight: bold;
}

.fa-tools {
    color: #993b78;
}

.forget {
    color: #993b78 !important;
    cursor: pointer;
}

.orderDetailsInfo1 {
    font-weight: bold;
    font-size: 13px;
    color: grey;
}

.orderDetailsInfo2 {
    font-weight: 400;
    font-size: 13px;
    color: grey;
}

.ant-pagination-item-active:hover,
.ant-pagination-item-active,
.ant-pagination-item-active a {
    border-color: #993b78 !important;
    color: #993b78 !important;
}

.formGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px 16px;
    gap: 8px 16px;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 16px;
}

.formGrid label {
    width: 100%;
}

.notificationButton {
    border-radius: 50px;
    width: 50px;
    height: 50px;
    border: none;
    outline: none;
    background: transparent;
    transition: background-color 200ms ease-in;
    position: relative;
}

.notificationButton:hover {
    background: rgba(211, 211, 211, 0.75)
}

.notificationDot {
    max-width: 30px;
    width: 30px;
    background: rebeccapurple;
    position: absolute;
    right: 0;
    bottom: 0;
    color: white;
    font-size: 12px;
    border-radius: 10px;
    transition: scale 50ms ease-in-out;
    scale: 1;
}

.notificationDotRead {
    scale: 0;
}

.textOverflow {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
    cursor: pointer;
}

.btn-primary .ant-spin-dot-item {
    background: white !important;
}

.btn-primary .ant-spin {
    -webkit-margin-end: 8px;
            margin-inline-end: 8px;
    height: 20px;
}
.myNav
{
  box-shadow: 0px 5px 6px 3px #ccc;

}

.logo {
  width: 175px;
  padding: 7px;
  padding-left: 40px;
}
.fa-bell,
.fa-envelope {
  color: #b8c3d5;
  font-size: 20px;
}
.adminDrop a,
.adminDrop a:hover {
  color: black;
  font-size: 18px;
}
.dropdown-menu {
    top:150%;
  right: 15% !important;
  left: unset !important;
}
.dropdown-item
{
    padding: 10px 100px 15px 20px;
    color : #6c757d

}
.dropdown-item:hover
{
    background-color: #e8eaec;

}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-left: 10px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #22ca80;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #22ca80;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .myDrop button,  .myDrop button:active,  .myDrop button:hover,.myDrop button:focus
  {
background-color: transparent !important;
border: unset !important;
box-shadow: unset !important;

}

:root {
    --notify-color-background: #eee;
    --notify-color-onBackground: #000;
    --notify-dimen-titleHeight: 72px;
}

.Notifications_container__1Samu {
    position: fixed;
    right: 0;
    bottom: 0;
    background-color: #eee;
    background-color: var(--notify-color-background);
    z-index: 200;
    max-width: 416px;
    min-width: 240px;
    width: 37%;
    margin: 8px;
    top: 100px;
    border-radius: 8px;
    padding: 0px !important;
    overflow: hidden;
    height: 70%;
    transition: top 100ms ease-in-out, opacity 200ms ease-in-out, height 100ms ease-in-out;
}

.Notifications_title__3kPPw {
    height: 72px;
    height: var(--notify-dimen-titleHeight);
    /*background-color: lightblue;*/
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    padding: 24px 28px;
    width: 100%;
    grid-gap: 8px;
    gap: 8px;

}

.Notifications_container__1Samu h1 {
    margin: 0 !important;
    font-weight: 600;
    font-size: 16px;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.Notifications_counter__q8XJ2 {
    padding: 8px 12px;
    -webkit-margin-end: -8px;
            margin-inline-end: -8px;
    line-height: 100%;
    background: transparent;
    border-radius: 26px;
    font-weight: 600;
    font-size: 16px;
    color: white;
    opacity: 0;
    transition: all 70ms ease-in-out;
}

.Notifications_headerIcon___WQJG {
    height: 24px;
    width: 24px;
    size: 24px;
}

.Notifications_arrow__13k63 {
    transition: width 70ms ease-in-out,
            -webkit-transform 170ms ease-in-out;
    transition: transform 170ms ease-in-out,
            width 70ms ease-in-out;
    transition: transform 170ms ease-in-out,
            width 70ms ease-in-out,
            -webkit-transform 170ms ease-in-out;
}

.Notifications_list__vNN_H {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
    padding: 0 8px 120px;
    height: calc(100% - 72px);
    overflow: auto;
}

.Notifications_list__vNN_H::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.Notifications_list__vNN_H::-webkit-scrollbar-track {
    background: #f0f1f2;
    border-radius: 8px;
}

/* Handle */
.Notifications_list__vNN_H::-webkit-scrollbar-thumb {
    background: #223d5a;
    border-radius: 8px;
}

/* Handle on hover */
.Notifications_list__vNN_H::-webkit-scrollbar-thumb:hover {
    background: #6fc4ad;
}

.Notifications_collapsed__30g_e {
    opacity: 0;
    height: 0;
}

.Notifications_collapsed__30g_e .Notifications_counter__q8XJ2 {
    opacity: 1;
    background: #ED3A3A;
}

.Notifications_collapsed__30g_e .Notifications_arrow__13k63 {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    width: 0;
    overflow: hidden;
}


.Notifications_notificationInfoMessage__36Ply {
    text-align: center;
    font-weight: bold;
    size: 18px;
    margin-top: 32px;
}
.NotificationCard_container__2Zdv6 {
    background: linear-gradient(0deg, rgba(6, 117, 255, 0.15), rgba(6, 117, 255, 0.15)), #FFFFFF;
    border: 0.5px solid #C3CCD6;
    border-radius: 6px;
    width: 100%;
    padding: 12px 16px;
}

.NotificationCard_header__1n044 {
    margin-bottom: 16px;
}


.NotificationCard_title__KotCa {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.NotificationCard_contentText__1N6Ka {
    font-weight: 400;
    line-height: 15px;
    color: #555555;
    font-size: 12px;
}

.NotificationCard_date__khtEG {
    text-align: end;
}

.NotificationCard_info__3Yp5v {
    font-size: 14px;
    line-height: 17px;
}

.NotificationCard_main__3z8UF {
    font-size: 14px;
    line-height: 17px;
    color: #2C2E31;
    font-weight: 600;
    margin-bottom: 8px;
}

.NotificationCard_icon__1e0lo {
    width: 38px;
    height: 38px;
    background: lightblue;
    border-radius: 50px;
}

.NotificationCard_actions__3xGVf {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-top: 16px;
    width: 100%;
}

.NotificationCard_extra__2ovB8 {
    margin-top: 8px;
    font-weight: 400;

}

.NotificationCard_actions__3xGVf button {
    padding: 10px 16px;
    background: linear-gradient(99.62deg, #0AAD8A -98.01%, #0AAD69 76.21%);
    border-radius: 6px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    outline: none;
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1 !important; /* number of lines to show */
    line-clamp: 1 !important;
    -webkit-box-orient: vertical;

}

.NotificationCard_remainingTime__N0lWe {
    background: #FFFFFF;
    border-radius: 32px;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #0085FF;
    margin-top: 16px;
}

.NotificationCard_notificationDot__kbUPi {
    width: 7px;
    height: 7px;
    background: #5A2B4B;
    border-radius: 20px;
}

.NotificationCard_read__Qq95H {
    background: #FFFFFF;
}

.NotificationCard_read__Qq95H .NotificationCard_notificationDot__kbUPi {
    display: none;
}
.BackDrop_BackDrop__2Ou-N {
    width: 100%;
    height: 100%;
    z-index: 100;
    left: 0;
    top: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
}
.Modal_Modal__2vMDy {
    position: fixed;
    z-index: 500;
    background-color: white;
    text-align: left;
    width: 70%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 15%;
    top: 30%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
    .Modal_Modal__2vMDy {
        width: 500px;
        left: calc(50% - 250px);
    }
}
.showMenuBtn {
  background-color: #fff;
  box-shadow: 4px 3px 8px 2px #ccc;
  border: none;
  position: absolute;
  top: 0px;
  left: 100%;
  height: 50px;
  width: 100px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  font-size: 30px;
  outline: none !important;
}

@-webkit-keyframes hideModel {
  from {
    left: 0;
  }
  to {
    left: -220px;
  }
}

@keyframes hideModel {
  from {
    left: 0;
  }
  to {
    left: -220px;
  }
}
@-webkit-keyframes showModel {
  from {
    left: -220px;
  }
  to {
    left: 0;
  }
}
@keyframes showModel {
  from {
    left: -220px;
  }
  to {
    left: 0;
  }
}

.my-menu {
  position: fixed;
  width: 220px;
  height: 100%;
  top: 115px;
  background-color: #fff;
  box-shadow: 0px 5px 6px 3px #ccc;
  z-index: 11;
}

.my-menu.show {
  -webkit-animation-name: showModel;
          animation-name: showModel;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  left: 0;
}

.my-menu.hide {
  -webkit-animation-name: hideModel;
          animation-name: hideModel;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  left: -220px;
}

.side-list {
  list-style-type: none;
  padding: unset;
  padding-top: 15px;
}
.side-list li a {
  color: #818181;
  font-size: 16px;
  padding: 12px 30px;
  display: -webkit-flex;
  display: flex;
  white-space: nowrap;
  -webkit-align-items: center;
          align-items: center;
  line-height: 27px;
  opacity: 0.7;
  text-decoration: none;
}
.side-list i {
  color: #9eabc0 !important;
  /* margin-right: 12px; */
}
.side-list .navitem-active {
  border-radius: 0 60px 60px 0;
  color: #fff !important;
  background: linear-gradient(
    to right,
    #5a2c4a,
    #963c76,
    #b3478d,
    #ce3b9b,
    #c72c91
  );
  box-shadow: 0 7px 12px 0 rgba(95, 118, 232, 0.21);
  opacity: 1;
}

.actionBtn {
  border-radius: 100%;
  padding: 6px;
  border: unset;
  /* margin-right: 5px; */
  color: #993b78;
  background-color: unset !important;
}
.actionBtn:hover {
  background-color: #fdc16a;
}
.actionBtn i {
  color: #993b78;
}
.gbjtWJ {
  display: none !important;
}
.seachInput {
  border: unset;
}

.headBtn,
.headBtn:active,
.headBtn:focus,
.headBtn:disabled {
  background-color: #993b78;
  color: white;
  border: unset !important;
  box-shadow: unset !important;
  margin: 4px;
  border-radius: unset;
}
.headBtn:hover {
  background-color: #fdc16a;
  color: white;
  border: unset !important;
  box-shadow: unset !important;
  margin: 4px;
  border-radius: unset;
}
.addP input {
  border: 1px solid #d9d9d9;
  color: #494e57;
}
.addBtn2,
.addBtn2:hover,
.addBtn2:focus,
.addBtn2:disabled {
  background-color: #993b78 !important;
  color: white !important;
  border: unset !important;
  box-shadow: unset !important;
  margin: 4px;
  border-radius: unset;
}
.addBtn1,
.addBtn1:hover,
.addBtn1:focus {
  background-color: #1c2d41;
  color: white;
  border: unset !important;
  box-shadow: unset !important;
  margin: 4px;
  border-radius: unset;
}

.supplierBtn,
.supplierBtn:hover,
.supplierBtn:focus {
  background-color: #6787f1;
  color: white;
  border: unset !important;
  box-shadow: unset !important;
  margin: 4px;
  border-radius: unset;
  border-radius: 3px;
}

.suppBtn,
.suppBtn:hover,
.suppBtn:focus,
.suppBtn:disabled {
  background-color: #6787f1;
  color: white;
  border: unset !important;
  box-shadow: unset !important;
  margin: 4px;
  border-radius: unset;
  border-radius: 50px;
}

.addP label {
  color: #7c8798;
}
.rdt_Table {
  border: 1px solid #dee3e7 !important;
}
.judJDM {
  /* border: 1px solid #dee3e7 !important; */
  margin-bottom: 5px;
}
/* .gQHdOi {
  padding: 18px ;
  padding-left: 40px !important;
} */
.link1 {
  color: #9eabc0;
  font-size: 18px;
}
.linkk {
  color: #993b78 !important;
  text-decoration: none !important;
}

/* 
.seachInput::-webkit-input-placeholder {
  color: #7c8798 !important;
  font-size: 13px;
}

.seachInput:-ms-input-placeholder {
  color:#7c8798 !important;
  font-size: 13px;
}

.seachInput::placeholder {
  color: #7c8798 !important;
  font-size: 13px;
} */

input:-internal-autofill-selected {
  background-color: transparent !important;
}

.actionBtn2 {
  border-radius: 3px;
  padding: 6px;
  background-color: #3ea8ff;
  border: unset;
  margin-right: 5px;
  width: 40px;
  outline: unset !important;
}
.actionBtn2 i {
  color: white;
}

.actionBtn3 {
  border-radius: 3px;
  padding: 6px;
  background-color: #6787f1;
  border: unset;
  margin-right: 5px;
  width: 40px;
  outline: unset !important;
}
.actionBtn3 i {
  color: white;
}

.actionBtn4 {
  border-radius: 3px;
  padding: 6px;
  background-color: rgb(250, 54, 54);
  border: unset;
  margin-right: 5px;
  width: 40px;
  outline: unset !important;
}
.actionBtn4 i {
  color: white;
}

.bZcmwf {
  min-width: 150px !important;
}

input::-webkit-input-placeholder {
  color: #7c8798 !important;
  font-size: 13px;
}

input:-ms-input-placeholder {
  color: #7c8798 !important;
  font-size: 13px;
}

input::placeholder,
.ant-select-selection-placeholder {
  color: #7c8798 !important;
  font-size: 13px;
}

.rdt_TableHeadRow .rdt_TableCol {
  color: #7c8798 !important;
  font-size: 16px !important;
  text-align: left;
  -webkit-justify-content: flex-start !important;
          justify-content: flex-start !important;
  /* background-color: red; */
  padding-left: unset;
}
.rdt_TableCell {
  color: #7c8798 !important;
  font-size: 16px !important;
  text-align: left;
  -webkit-justify-content: flex-start !important;
          justify-content: flex-start !important;
  padding: 18px;
  /* padding-left: 40px !important */
}

.viewSupp .rdt_Table {
  padding: 15px;
}
.customBtn {
  width: 400px;
}
.customBtn::-webkit-file-upload-button {
  /* visibility: hidden; */
  /* background-color: red; */
  /* width:400px */
  display: none;
}
.customBtn::before {
  content: "Upload Suppliers";
  display: inline-block;
  background: #993b78;
  border: unset;
  /* border-radius: 3px; */
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  /* font-size: 10pt; */
  color: white;
  padding: 6px;
  width: 140px;
  font-size: 16px;
  vertical-align: middle;
  margin-right: 5px;
}
.customBtn:hover::before {
  /* border-color: black; */
}
.customBtn:active::before {
  background: #993b78;
}

.customBtn2 {
  width: 400px;
}
.customBtn2::-webkit-file-upload-button {
  /* visibility: hidden; */
  /* background-color: red; */
  /* width:400px */
  display: none;
}
.customBtn2::before {
  content: "Upload Couriers";
  display: inline-block;
  background: #993b78;
  border: unset;
  /* border-radius: 3px; */
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  /* font-size: 10pt; */
  color: white;
  padding: 6px;
  width: 140px;
  font-size: 16px;
  vertical-align: middle;
  margin-right: 5px;
}
.customBtn2:hover:before {
  background-color: #fdc16a;
}

.customBtn2:hover::before {
  /* border-color: black; */
}
.customBtn2:active::before {
  background: #993b78;
}

.customBtn3 {
  width: 400px;
}
.customBtn3::-webkit-file-upload-button {
  /* visibility: hidden; */
  /* background-color: red; */
  /* width:400px */
  display: none;
}
.customBtn3::before {
  content: "Upload Catalog";
  display: inline-block;
  background: #993b78;
  border: unset;
  /* border-radius: 3px; */
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  /* font-size: 10pt; */
  color: white;
  padding: 6px;
  width: 140px;
  font-size: 16px;
  vertical-align: middle;
  margin-right: 5px;
}
.customBtn3:hover:before {
  background-color: #fdc16a;
}

.customBtn3:hover::before {
  /* border-color: black; */
}
.customBtn3:active::before {
  background: #993b78;
}

.indexedStep {
  color: white;
  width: 30px;
  height: 30px;
  font-size: 10px;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border: solid 1px black;
}

.indexedStep.accomplished,
.RSPBprogressBar .RSPBprogression {
  background-color: #993b78 !important;
}

.RSPBprogressBar {
  background-color: rgba(211, 211, 211, 1) !important;
}
.btn1,
.btn1:active,
.btn1:focus,
.btn1:hover {
  background-color: #79cf93 !important;
  color: white;
  border: unset !important;
  box-shadow: unset !important;
  margin: 4px;
  border-radius: unset;
}
.btn2,
.btn2:active,
.btn2:focus,
.btn2:hover {
  background-color: #ff8585 !important;
  color: white;
  border: unset !important;
  box-shadow: unset !important;
  margin: 4px;
  border-radius: unset;
}
.btn3,
.btn3:active,
.btn3:focus,
.btn3:hover {
  background-color: #993b78 !important;
  color: white;
  border: unset !important;
  box-shadow: unset !important;
  margin: 4px;
  border-radius: unset;
}
.custom::-webkit-file-upload-button {
  border: unset !important;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: #6d7174;
  -webkit-transition: 1s;
  transition: 1s;
  color: white;
  padding: 7px 21px;
  margin-left: 7px;
}

.custom2::-webkit-file-upload-button {
  border: unset !important;
  padding: 0.2em 0.4em;
  border-radius: unset;
  background-color: #993b78;
  -webkit-transition: 1s;
  transition: 1s;
  color: white;
  padding: 7px 21px;
  margin-left: 7px;
}

.ant-picker {
  width: 100%;
}

.btn4,
.btn4:active,
.btn4:focus,
.btn4:hover {
  background-color: #8dcbdf !important;
  color: white;
  border: unset !important;
  box-shadow: unset !important;
  margin: 4px;
  border-radius: unset;
  /* opacity: .9; */
}

.btn5,
.btn5:active,
.btn5:focus,
.btn5:hover {
  background-color: #e5e7e7 !important;
  color: black;
  border: unset !important;
  box-shadow: unset !important;
  margin: 4px;
  border-radius: unset;
  /* opacity: .9; */
}

.modal2 label {
  color: #7c8798;
  font-size: 16px;
}

.ant-collapse > .ant-collapse-item,
.ant-collapse > .ant-collapse-item > .ant-collapse-header,
.ant-collapse-content {
  border-bottom: unset !important;
  border-top: unset !important;
  font-size: 16px;
  font-weight: 600;
}
.ant-collapse,
.ant-collapse-content {
  border: unset !important;
  font-size: 14px;
  font-weight: unset;
  padding-left: 30px;
}
.btn-primary,
.btn-primary:hover,
.btn-primary:focus {
  background-color: #993b78 !important;
  border-color: unset !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header,
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  font-size: 18px !important;
}

.checkk {
  color: green;
  font-size: 25px;
}

.my-modal {
  width: 50%;
  max-width: 90vw;
}

.form-label {
  font-weight: bolder;
}

.ant-radio-inner {
  border-color: rgb(65, 65, 65) !important;
}

.courierTable i {
  color: #993b78 !important;
}

.nextBtn,
.nextBtn:active,
.nextBtn:focus,
.nextBtn:hover,
.nextBtn:disabled,
.addBtn,
.addBtn:active,
.addBtn:focus,
.addBtn:hover,
.addBtn:disabled {
  background-color: transparent !important;
  border: unset !important;
  box-shadow: unset !important;
  color: #993b78;
}

.nav.nav-pills .nav-link.active {
    background-color:#993B78
}

.noSelection {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
        
}

.groupingDate {
    text-align: center;
    margin: 5px;
    color: #7B2F60;
    font-weight: 600;
}

.chatContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    /* height: 100%; */
    height: 500px;
    min-width: 350px;
    background-color: #F7ECF3;
}

.noMessagesAlert {
    display: -webkit-flex;
    display: flex;
    height: 700px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.chatHeader {
    background-color:#E3D5E6;
    padding: 15px 10px 0px 15px;
    font-weight: bold; 
    z-index: 2; 
}

.messagesList {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    overflow-y: auto;
    padding: 0px 10px;
}

.inputFragment {
    display: -webkit-flex;
    display: flex;
    /* align-content: center; */
    padding: 8px;

    -webkit-align-items: center;

            align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  
}

.messageTextField{ 
    -webkit-flex-grow: 1; 
            flex-grow: 1;
    background: #E3D5E6;
    border: 2px solid #ffffff00;
    padding: 8px 8px;
    border-radius: 20px;
    outline: none;
    margin: 0px 10px 0px 5px;
    min-width: none;
    max-height: 35px;
}
.chatButtonsContainer {
    display: -webkit-flex;
    display: flex;
    width: 33px;
    height: 34px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 1.8px;
    position: relative;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    /* top: 3px; */
    border-radius: 100%;
    cursor: pointer;
}

.chatButtons {
    color: #993B78;
    
    /* border-radius: 100%; */
    /* margin: 3px; */
    padding: 3.5px;
    width: 30px;
    height: 30px;
    margin: auto 0px;
    transition: width 0.1s, height 0.1s;
}



.chatButtonsContainer:hover {
    background-color: #d1c4d465;
    cursor: pointer;
    border-radius: 100%;
    
}


.chatButtons:active {
    color: #FDC16A;
    /* width: 29px;
    height: 29px; */
}

.previewImages {
    border-radius: 15px;
    min-height:70px;
    max-height: 100px;
    margin: 3px;
    object-fit: cover;
    padding: 2px;
  }

  .imageContainers:hover {
    cursor: pointer;
  }

  .loadMoreButton {
      background-color: #993B78;
      color: white;
      border-radius: 15px;
      margin: 10px;
      text-align: center;
  }

  .loadMoreButton:hover {
    background-color: #FDC16A;
  }
.message {
  padding: 8px 16px;
  display: inline-block;
  border-radius: 20px;
  width: auto;
  max-width: 60%;
  word-wrap: break-word;
  text-align: left;
}

.sentMessage {
  background-color: #993B78;
  color: white;
  /* margin: 10px 0px; */
  margin-left: 50px;
}

.recievedMessage{
  background-color: #E3D5E6;
  margin-top: 2px;
}

.typingIndecator {
  background-color: #C0C5CF;
  margin-top: 2px;
  font-style: italic;
  color: #7F7F7F;
}

.imageContainers {
  border-radius: 20px;
  height:100px;
   width: 100px;
  margin: 3px;
  object-fit: cover;
  background-color: #E3D5E6;
  padding: 2px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.imageContainers:hover {
  cursor: pointer;
  background-color: #993B78;
}

.imageContainers:active {
  cursor: pointer;
  padding: 5px;
}

.voiceMessage {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 40px;
  margin: 0px;
}

.secondaryText {
  color: #9C9C9C;
  margin:0px 9px;
  font-size: .8em;
}

.rhap_container {
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    line-height: 1;
    font-family: inherit;
    width: 200px;
    /* background-color: #993B78; */
  }
  .rhap_container:focus:not(:focus-visible) {
    outline: 0;
  }
  .rhap_container svg {
    vertical-align: baseline;
    vertical-align: initial;
    color:#CDB4D3;
  }
  
  /* .rhap_header {
    margin-bottom: 10px;
  } */
  
  /* .rhap_footer {
    margin-top: 5px;
  } */
  
  .rhap_main {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
  }
  
  /* .rhap_stacked .rhap_controls-section {
    margin-top: 8px;
  }
   */
  /* .rhap_horizontal {
    flex-direction: row;
  } */
  /* .rhap_horizontal .rhap_controls-section {
    margin-left: 8px;
  } */
  
  .rhap_horizontal-reverse {
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    height: 100%;
  }

  /* .rhap_horizontal-reverse .rhap_controls-section {
    margin-right: 8px;
  } */
  
  .rhap_stacked-reverse {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  /* .rhap_stacked-reverse .rhap_controls-section {
    margin-bottom: 8px;
  } */
  
  .rhap_progress-section {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 3 1;
            flex: 3 1;
    -webkit-align-items: center;
            align-items: center;
    padding: 0%;
    height: 33px;
    position: relative;
    top: -4.8px;
    left: -20px;
    margin-right: -32px;
    width: 100%;
    overflow: hidden;
    
  }
  
  .rhap_progress-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    height: 33px;
    width: 100%;
    -webkit-flex: 1 0 auto;
            flex: 1 0 auto;
    -webkit-align-self: center;
            align-self: center;
    /* margin: 0 calc(10px + 1%); */
    cursor: pointer;
    -webkit-user-select: none;
    border-radius: 20px;
    position: relative;
    overflow: hidden;

    /* left: -20px; */
  }
  .rhap_progress-container:focus:not(:focus-visible) {
    outline: 0;
  }
  
  .rhap_time {
    color: #333;
    font-size: 12px;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
  }
  
  .rhap_progress-bar {
    box-sizing: border-box;
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: #CDB4D3;
    border-radius: 20px;

  }
  
  .rhap_progress-filled {
    height: 100%;
    position: absolute;
    z-index: 2;
    background-color: #CDB4D3;
    /* border-radius: 20px 20px; */
  }
  
  .rhap_progress-bar-show-download {
    background-color: #ddd
  }
  
  .rhap_download-progress {
    height: 100%;
    position: absolute;
    z-index: 1;
    background-color: #F9F1FB;
    border-radius: 20px;
  }
  
  .rhap_progress-indicator {
    box-sizing: border-box;
    position: absolute;
    z-index: 3;
    width: 20px;
    height: 20px;
    margin-left: -10px;
    /* background: #F7ECF3; */
    border-radius: 50px;
  }
  
  .rhap_controls-section {
    display: -webkit-flex;
    display: flex;
    /* flex: 1 1 auto; */
    position: relative;
    left: -18px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
  }
  
  .rhap_additional-controls {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 0 auto;
            flex: 1 0 auto;
    -webkit-align-items: center;
            align-items: center;
  }
  
  .rhap_repeat-button {
    font-size: 26px;
    width: 26px;
    height: 26px;
    color: #868686;
    margin-right: 6px;
  }
  
  .rhap_main-controls {
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }

  .rhap_main-controls-button {
    margin: 0 3px;
    color: #868686;
    font-size: 35px;
    width: 35px;
    height: 35px;
  }
  
  .rhap_play-pause-button {
    font-size: 40px;
    width: 40px;
    height: 40px;
  }
  
  .rhap_volume-controls {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 0 auto;
            flex: 1 0 auto;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
  }
  
  .rhap_volume-button {
    -webkit-flex: 0 0 26px;
            flex: 0 0 26px;
    font-size: 26px;
    width: 26px;
    height: 26px;
    color: #868686;
    margin-right: 6px;
  }
  
  .rhap_volume-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex: 0 1 100px;
            flex: 0 1 100px;
    -webkit-user-select: none;
  }
  
  .rhap_volume-bar-area {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    height: 14px;
    cursor: pointer;
  }
  .rhap_volume-bar-area:focus:not(:focus-visible) {
    outline: 0;
  }
  
  .rhap_volume-bar {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 4px;
    background: #dddddd;
    border-radius: 2px;
  }
  
  .rhap_volume-indicator {
    box-sizing: border-box;
    position: absolute;
    width: 12px;
    height: 12px;
    margin-left: -6px;
    left: 0;
    top: -4px;
    background: #868686;
    opacity: 0.9;
    border-radius: 50px;
    box-shadow: rgba(134, 134, 134, 0.5) 0 0 3px;
    cursor: pointer;
  }
  .rhap_volume-indicator:hover {
    opacity: 0.9;
  }
  
  .rhap_volume-filled {
    height: 100%;
    position: absolute;
    z-index: 2;
    background-color: #868686;
    border-radius: 2px;
  }
  
  /* Utils */
  .rhap_button-clear {
    background-color: transparent;
    border: none;
    padding: 0;
    overflow: hidden;
    cursor: pointer;
  }
  .rhap_button-clear:hover {
    opacity: 0.9;
    transition-duration: 0.2s;
  }
  .rhap_button-clear:active {
    opacity: 0.95;
  }
  .rhap_button-clear:focus:not(:focus-visible) {
    outline: 0;
  }
  
.imageViewerContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 70vh;
}

.currentImage {
    overflow: hidden;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.imagesContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    height: 90px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    background-color: #F7ECF3;
    margin: 0px 10px 10px 10px;
    border-radius: 15px;
}

.imagesList {
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    height: 100%;
    padding: 5px 0px;
    overflow-x: auto;

}


.imagesList img{
    height: 70px;
    width: 70px;
}

.viewedImage {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    max-height: 100%;
    max-width: 100%;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 5px;

    /* flex-grow: 1; */
}

.buttonsContainers {
    padding: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.scrollButtonsIcon {
    width: 30px;
    height: 30px;
    color: #993B78;
    border-radius: 100%;
    padding: 3px;
}

.scrollButtonsIcon:hover {
    background-color: #e2e2e277;
    cursor: pointer;

}

.scrollButtonsIcon:active {
    color: #FDC16A;
}

.zoomButtons {
    background-color: #993B78;
}

.zoomButtons:hover { 
    background-color: #FDC16A;
}

.zoomButtons:active { 
    background-color: #FDC16A !important;
}

.zoomButtons:disabled {
    background-color: #996487;
}

.loadingFragment {
    position: absolute;
    z-index: 3;
    /* background-color: #993B78; */
    /* height: 100%; */
}
.panelLabel {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.panelLabel button {
    background-color: transparent;
    border: none;
    outline: none;
    width: 32px;
    height: 32px;
    border-radius: 50px;
}

.panelLabel button:hover {
    background-color: rgba(211, 211, 211, 0.58);
}
.CourierServicesForm_container__3xfii {
}

.CourierServicesForm_common__2Y1m4 {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    grid-gap: 16px;
    gap: 16px;
}

.CourierServicesForm_service__I1vkT {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px 16px;
    gap: 8px 16px;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 16px;
}

.CourierServicesForm_service__I1vkT header {
    grid-column: 1/3;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.CourierServicesForm_service__I1vkT header h5 {
    margin-bottom: 0 !important;
}

.CourierServicesForm_service__I1vkT button {
    margin-top: 9px;
}

.CourierServicesForm_serviceList__QAmI4 {
    margin: 16px 0 ;
}

.CourierServicesForm_serviceList__QAmI4 button {
    background-color: #993b78;
    padding: 8px 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    grid-gap: 4px;
    gap: 4px;
    -webkit-align-items: center;
            align-items: center;
}

.CourierServicesForm_serviceList__QAmI4 button:disabled {
    background-color: darkgray !important;
}

.CourierServicesForm_serviceList__QAmI4 form div {
    margin-bottom: 0;
}
.EmiratesTable_table__1-8ZE {
    border: 1px solid #993b78;
    border-radius: 8px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1px 2fr;

}


.EmiratesTable_divider__2R8ev {
    background-color: #993b78;
    height: 100%;
    width: 1px;
}

.EmiratesTable_emiratesList__8wysD {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;

}

.EmiratesTable_emirateListItem__3GcdT {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    grid-gap: 8px;
    gap: 8px;
    font-size: 18px;
    padding: 8px 16px;
    border-bottom: 1.5px solid #993b78;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    overflow: auto;
}

.EmiratesTable_emirateListItem__3GcdT:last-child {
    border: none;
}

.EmiratesTable_table__1-8ZE hr {
    background-color: #993b78;
    opacity: 1;
    margin: 0;
    margin-bottom: 16px;
}

.EmiratesTable_emiratePrice__-ndJX {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 16px 16px;
    font-size: 18px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.EmiratesTable_table__1-8ZE input {
    border-radius: 4px;
    outline: none;
    border: 1px solid gray;
}


.EmiratesTable_areasList__3NhOW {
    background-color: #993b7811;
    min-height: 100%;
}

.EmiratesTable_selected__3W4MU {
    background-color: lightgrey;
}

.EmiratesTable_tableHeader__dUVHg select {
    font-size: 20px;
    background-color: transparent;
}

.EmiratesTable_tableHeader__dUVHg input::-webkit-input-placeholder {
    font-size: 20px;
    background-color: transparent;
}

.EmiratesTable_tableHeader__dUVHg input:-ms-input-placeholder {
    font-size: 20px;
    background-color: transparent;
}

.EmiratesTable_tableHeader__dUVHg input,
.EmiratesTable_tableHeader__dUVHg input::placeholder {
    font-size: 20px;
    background-color: transparent;
}
.CourierChatComponent_container__bAsAX {
    position: fixed;
    z-index: 10;
    bottom: 0;
    right: 24px;
    border-radius: 10px 10px 0 0;
    border: 1px solid lightgrey;
    -webkit-transform: translateY(485px);
            transform: translateY(485px);
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    max-width: 352px;
    background: #E3D5E6;
}

.CourierChatComponent_container__bAsAX > header {
    background: #E3D5E6;
    padding-inline: 15px;
    padding-top: 18px;
    border-radius: 10px 10px 0 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
}

.CourierChatComponent_container__bAsAX > header button {
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.CourierChatComponent_container__bAsAX > header h5 {
    margin-bottom: 0 !important;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    text-overflow: ellipsis;
}

.CourierChatComponent_open__R7e-u {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
}

.CourierChatComponent_open__R7e-u > header button {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
.keysHolder
{
    width: inherit;
    height: auto;
    min-height: 300px;
    background-color: #e8eaec;

}
/* .keysHolder .badge
{
    font-size: 13px;
    width: 100px;
    padding: 10px;
    border-radius: unset;
    background-color: white;
    color:#6c757d;
    background-color: rgba(255,255,255,.85);
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,.1);
    
} */

.tst
{
    max-width: 100px !important;    display: inline-block !important;
    margin-left: 4px;

}
.toast:not(:last-child)
{
    margin-bottom: unset !important;
}

.keysBtn,.keysBtn:hover,.keysBtn:active,.keysBtn:focus
{
    background-color: white !important;
    color:grey !important;
    border:transparent !important;
    box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,.1) !important;
    margin-right: 5px;
    font-weight: bold;
}
