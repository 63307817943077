.message {
  padding: 8px 16px;
  display: inline-block;
  border-radius: 20px;
  width: auto;
  max-width: 60%;
  word-wrap: break-word;
  text-align: left;
}

.sentMessage {
  background-color: #993B78;
  color: white;
  /* margin: 10px 0px; */
  margin-left: 50px;
}

.recievedMessage{
  background-color: #E3D5E6;
  margin-top: 2px;
}

.typingIndecator {
  background-color: #C0C5CF;
  margin-top: 2px;
  font-style: italic;
  color: #7F7F7F;
}

.imageContainers {
  border-radius: 20px;
  height:100px;
   width: 100px;
  margin: 3px;
  object-fit: cover;
  background-color: #E3D5E6;
  padding: 2px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.imageContainers:hover {
  cursor: pointer;
  background-color: #993B78;
}

.imageContainers:active {
  cursor: pointer;
  padding: 5px;
}

.voiceMessage {
  display: flex;
  flex-direction: row;
  height: 40px;
  margin: 0px;
}

.secondaryText {
  color: #9C9C9C;
  margin:0px 9px;
  font-size: .8em;
}
