.container {
    background: linear-gradient(0deg, rgba(6, 117, 255, 0.15), rgba(6, 117, 255, 0.15)), #FFFFFF;
    border: 0.5px solid #C3CCD6;
    border-radius: 6px;
    width: 100%;
    padding: 12px 16px;
}

.header {
    margin-bottom: 16px;
}


.title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.contentText {
    font-weight: 400;
    line-height: 15px;
    color: #555555;
    font-size: 12px;
}

.date {
    text-align: end;
}

.info {
    font-size: 14px;
    line-height: 17px;
}

.main {
    font-size: 14px;
    line-height: 17px;
    color: #2C2E31;
    font-weight: 600;
    margin-bottom: 8px;
}

.icon {
    width: 38px;
    height: 38px;
    background: lightblue;
    border-radius: 50px;
}

.actions {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 16px;
    width: 100%;
}

.extra {
    margin-top: 8px;
    font-weight: 400;

}

.actions button {
    padding: 10px 16px;
    background: linear-gradient(99.62deg, #0AAD8A -98.01%, #0AAD69 76.21%);
    border-radius: 6px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    outline: none;
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1 !important; /* number of lines to show */
    line-clamp: 1 !important;
    -webkit-box-orient: vertical;

}

.remainingTime {
    background: #FFFFFF;
    border-radius: 32px;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #0085FF;
    margin-top: 16px;
}

.notificationDot {
    width: 7px;
    height: 7px;
    background: #5A2B4B;
    border-radius: 20px;
}

.read {
    background: #FFFFFF;
}

.read .notificationDot {
    display: none;
}